import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

import imgR from '../assets/images/pages/gracias-por-tu-solicitud/imgR.png';

import '../assets/styles/pages/gracias-por-tu-solicitud.scss';

const GraciasPorTuSolicitud = () => (
  <Layout
    title="¡Gracias por la Solicitud! | ELE Barcelona"
    description="Notificación de que hemos recibido tu solicitud y te hemos enviado un email de confirmación. Nos pondremos en contacto contigo en menos de 12 horas. ¡Gracias!"
  >
    <div className="gracias-por-tu-solicitud">
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="c-title-34">¡Gracias por contactar con nosotros!</h3>
              <p className="second-section__description c-text-15 c-text-15--gray-100">
                Hemos recibido tu solicitud y la revisaremos dentro de poco. Normalmente tardamos un
                día en hacerlo y nos pondremos en contacto contigo muy pronto para organizar una
                entrevista personal.
                <br />
                <br />
                Te hemos enviado un email de confirmación, si no lo recibes por favor comprueba tu
                bandeja de spam.
              </p>
              <Link to="/" className="c-btn c-btn--green">
                Volver a la página de inicio
              </Link>
            </div>
            <div className="col-md-6">
              <img className="img-r" src={imgR} alt="imgR" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default GraciasPorTuSolicitud;
